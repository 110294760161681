const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'

const translationEl = {
  home: 'Αρχική',
  cases: 'Περιστατικά',
  profile: 'Προφίλ',
  yourNotifications: 'Οι Ειδοποιήσεις σας',
  yourCases: 'Τα Περιστατικά σας',
  status: 'Κατάσταση',
  id: 'ID',
  startDate: 'Ημερομηνία Έναρξης',
  caseName: 'Όνομα Περιστατικού',
  [lorem]: lorem,
  pageNotFound: 'Ουπς! Η Σελίδα Δεν Βρέθηκε.',
  errorPageMessage:
    'Λυπούμαστε, αλλά η σελίδα που ψάχνετε ίσως έχει αφαιρεθεί, έχει αλλάξει όνομα ή είναι προσωρινά μη διαθέσιμη.',
  cancel: 'Ακύρωση',
  save: 'Αποθήκευση',
  email: 'Email',
  username: 'Όνομα χρήστη',
  specialization: 'Ειδικότητα',
  institution: 'Ινστιτούτο',
  languageSettings: 'Ρυθμίσεις γλώσσας',
  selectLanguage: 'Επιλέξτε γλώσσα',
  welcome: 'Καλώς ορίσατε',
  allCases: 'Όλα τα Περιστατικά',
  filters: 'Φίλτρα',
  date: 'Ημερομηνία',
  selectFilters: 'Επιλέξτε Φίλτρα',
  selectStartDate: 'Αρχική Ημερομηνία',
  selectEndDate: 'Τελική Ημερομηνία',
  clearFilters: 'Εκκάθαρση Φίλτρων',
  apply: 'Εφαρμογή',
  signInToYourAccount: 'Συνδεθείτε στο λογαριασμό σας',
  password: 'Κωδικός πρόσβασης',
  logIn: 'Σύνδεση',
  signUp: 'Εγγραφείτε',
  yourEmail: 'Το email σας',
  forgotPassword: 'Ξεχάσατε τον κωδικό σας;',
  rememberMe: 'Να με θυμάσαι!',
  noAccountYet: 'Δεν έχετε λόγαριασμό;',
  edit: 'Επεξεργασία',
  name: 'Όνομα',
  caseId: 'ID Περιστατικού',
  result: 'Αποτέλεσμα',
  positive: 'Θετικό',
  negative: 'Αρνητικό',
  sex: 'Φύλο',
  age: 'Ηλικία',
  male: 'Άνδρας',
  female: 'Γυναίκα',
  earlyDiagnostics: 'Πρώιμα Διαγνωστικά',
  suggestions: 'Προτάσεις',
  riskScore: 'Ποσοστό Ρίσκου',
  riskFactors: 'Παράγοντες Ρίσκου',
  bmi: 'BMI',
  activityLevel: 'Σωματικής Άσκηση',
  colonoscopy: 'Κολονοσκόπηση',
  ctScan: 'CT Scan',
  labTests: 'Εργαστηριακές Εξετάσεις',
  reports: 'Αναφορές',
  imaging: 'Απεικόνηση',
  findings: 'Ευρήματα',
  preparationDetails: 'Λεπτομέρειες Προετοιμασίας',
  procedureDetails: 'Λεπτομέρειες Διαδικασίας',
  procedureImages: 'Εικόνες Διαδικασίας',
  image: 'Εικόνα',
  bloodTests: 'Αιματολογικές Εξετάσεις',
  molecularTests: 'Μοριακές Δοκιμές',
  viewResults: 'Αποτελέσματα',
  histopathologyFindings: 'Ιστοπαθολογικά Ευρήματα',
  personalInfo: 'Προσωπικές Πληροφορίες',
  viewAllUpdates: 'Δείτε Όλες τις Ενημερώσεις',
  welcomeBack: 'Καλώς ήρθατε στην επιφάνεια εργασίας σας.',
  search: 'Αναζήτηση',
  addCase: 'Προσθήκη Περιστατικού',
  pleaseCreateAnAccount: 'Παρακαλώ δημιουργείστε λογαριασμό.',
  next: 'Επόμενο',
  alreadyHaveAccount: 'Έχετε ήδη λογαριασμό;',
  signIn: 'Είσοδος',
  yourAccount: 'Ο λογαριασμός σας',
  aboutYou: 'Σχετικά με εσάς',
  verification: 'Επαλήθευση',
  fullName: 'Ονοματεπώνυμο',
  specialty: 'Ειδικότητα',
  medicalInstitution: 'Ιατρικό Ινστιτούτο',
  back: 'Πίσω',
  verificationText:
    'Ελέγξτε το email σας και εισάγετε τον κωδικό επαλήθευσης που σας έχουμε στείλει στον εγγεγραμμένο λογαριασμό email σας',
  code: 'Κωδικός',
  resendCode: 'Επαναποστολή Κωδικού',
  verifyAccount: 'Επαλήθευση',
  firstName: 'Όνομα',
  lastName: 'Επώνυμο',
  userType: 'Τύπος Χρήστη',
  doctor: 'Ιατρός',
  nurse: 'Νοσοκόμα',
  maintainer: 'Συντηρητής',
  author: 'Δημιουργός',
  typeFileName: 'Τύπος - Όνομα Αρχείου',
  uploadFiles: 'Ανεβάστε Αρχεία',
  forgotPasswordDescription: 'Μην ανησυχείτε! Εισάγετε το email σας παρακάτω για να λάβετε οδηγίες επαναφοράς του κωδικού πρόσβασης.',
  sendEmail: 'Αποστολή Email',
  backToSingIn: 'Πίσω στην Σύνδεση',
  confirmPasswordRequired: 'Απαιτείται επιβεβαίωση κωδικού',
  passwordsMustMatch: 'Οι κωδικοί πρέπει να ταιριάζουν',
  confirmPassword: 'Επιβεβαίωση Κωδικού',
  resetYourPassword: 'Επαναφορά Κωδικού',
  resetPasswordDescription: 'Ο νέος σας κωδικός πρόσβασης πρέπει να είναι διαφορετικός από τον προηγούμενο.',
  oncoCtc: 'ONCO CTC',
  oncoNmr: 'ONCO NMR',
  addNewFile: 'Προσθήκη Νέου Αρχείου',
}

// dummy commit

export default translationEl
