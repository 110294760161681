const notifications = [
  {
    text: 'New case with ID CCS005 added.',
    time: '15:01',
  },
  {
    text: 'Case with ID CCS003 has been closed.',
    time: '14:34',
  },
  {
    text: 'New results added for case with ID CCS004.',
    time: '13:21',
  },
]

export default notifications
